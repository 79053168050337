import * as _internalSlot2 from "internal-slot";
var _internalSlot = "default" in _internalSlot2 ? _internalSlot2.default : _internalSlot2;
var exports = {};
var SLOT = _internalSlot;
var $SyntaxError = SyntaxError;
var $StopIteration = typeof StopIteration === "object" ? StopIteration : null;
exports = function getStopIterationIterator(origIterator) {
  if (!$StopIteration) {
    throw new $SyntaxError("this environment lacks StopIteration");
  }
  SLOT.set(origIterator, "[[Done]]", false);
  var siIterator = {
    next: function next() {
      var iterator = SLOT.get(this, "[[Iterator]]");
      var done = SLOT.get(iterator, "[[Done]]");
      try {
        return {
          done: done,
          value: done ? void undefined : iterator.next()
        };
      } catch (e) {
        SLOT.set(iterator, "[[Done]]", true);
        if (e !== $StopIteration) {
          throw e;
        }
        return {
          done: true,
          value: void undefined
        };
      }
    }
  };
  SLOT.set(siIterator, "[[Iterator]]", origIterator);
  return siIterator;
};
export default exports;